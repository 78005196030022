<div [formGroup]="parentForm">
  <div formArrayName="DynamicFields">
    <mat-card class="custom-card mt-3">
      <div class="row mt-2">
        <div class="col-md-8 dynamic-field-container">
          <mat-card-content>
            <div class="row">
              <div class="col-md-12">
                <p class="formSubTitle mt-2">
                  {{ 'COMMON.ADDITIONAL_INFORMATION' | translate }}
                </p>
              </div>
            </div>

            <div class="container-fluid">
              <div class="row">
                <table>
                  <tr>
                    <div class="row">
                      <div class="col-md-6 d-flex justify-content-center">
                        <th>{{ 'COMMON.DYNAMIC_PROPERTY' | translate }}</th>
                      </div>
                      <div class="col-md-6 d-flex justify-content-center">
                        <th>
                          {{ 'COMMON.VALUE' | translate }}
                        </th>
                      </div>
                    </div>
                  </tr>

                  <tr
                    *ngFor="let field of formArray?.controls; let i = index"
                    [formGroupName]="i"
                  >
                    <div class="row mt-2">
                      <div class="col-md-6">
                        <mat-form-field
                          class="example-full-width form-input-field"
                        >
                          <input type="text" matInput formControlName="Label" />
                        </mat-form-field>
                      </div>

                      <div class="col-md-6">
                        <ng-container
                          [ngSwitch]="field?.get('DataType')?.value"
                        >
                          <div *ngSwitchCase="dataType.TEXT">
                            <mat-form-field
                              class="example-full-width form-input-field"
                            >
                              <input
                                type="text"
                                matInput
                                formControlName="Value"
                                [required]="field?.get('Label')?.value.label"
                              />
                            </mat-form-field>
                          </div>

                          <div *ngSwitchCase="dataType.URL">
                            <mat-form-field
                              class="example-full-width form-input-field"
                            >
                              <mat-label>{{
                                field?.get('label')?.value
                              }}</mat-label>
                              <input
                                type="text"
                                matInput
                                formControlName="Value"
                                [required]="field?.get('Label')?.value.label"
                              />
                            </mat-form-field>
                          </div>

                          <div *ngSwitchCase="dataType.NUMBER">
                            <mat-form-field
                              class="example-full-width form-input-field"
                            >
                              <input
                                type="number"
                                matInput
                                formControlName="Value"
                                [required]="field?.get('Label')?.value.label"
                              />
                            </mat-form-field>
                          </div>

                          <div *ngSwitchCase="dataType.DATE">
                            <mat-form-field
                              class="example-full-width form-input-field"
                            >
                              <input
                                matInput
                                [matDatepicker]="picker1"
                                formControlName="Value"
                                readonly
                                [required]="field?.get('Label')?.value.label"
                              />
                              <mat-datepicker-toggle
                                matIconSuffix
                                [for]="picker1"
                              ></mat-datepicker-toggle>
                              <mat-datepicker #picker1></mat-datepicker>
                            </mat-form-field>
                          </div>

                          <div
                            *ngSwitchCase="dataType.BOOLEAN"
                            class="row toggle-container"
                          >
                            <mat-slide-toggle
                              formControlName="Value"
                            ></mat-slide-toggle>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </tr>
                </table>
              </div>
            </div>
          </mat-card-content>
        </div>
      </div>
    </mat-card>
  </div>
</div>
