<div [formGroup]="parentForm">
  <div class="row mt-3" *ngIf="formArray?.controls.length != 0">
    <div class="col-md-12">
      <p class="formSubTitle">
        {{ 'COMMON.ADDITIONAL_INFORMATION' | translate }}
      </p>
    </div>
  </div>

  <div [formArrayName]="formArrayName" class="row">
    <div
      *ngFor="let field of formArray?.controls; let i = index"
      [formGroupName]="i"
      class="col-md-6"
    >
      <ng-container [ngSwitch]="field?.get('DataType')?.value">
        <div *ngSwitchCase="dataType.TEXT">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label class="text-capitalize">{{
              field?.get('Label')?.value
            }}</mat-label>
            <input
              type="text"
              matInput
              formControlName="Value"
              pattern="\S(.*\S)?"
            />
          </mat-form-field>
        </div>

        <div *ngSwitchCase="dataType.URL">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label class="text-capitalize">{{
              field?.get('Label')?.value
            }}</mat-label>
            <input
              type="text"
              matInput
              formControlName="Value"
              pattern="\S(.*\S)?"
            />
          </mat-form-field>
        </div>

        <div *ngSwitchCase="dataType.NUMBER">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label class="text-capitalize">{{
              field?.get('Label')?.value
            }}</mat-label>
            <input type="number" matInput formControlName="Value" />
          </mat-form-field>
        </div>

        <div *ngSwitchCase="dataType.DATE">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label class="text-capitalize">{{
              field?.get('Label')?.value
            }}</mat-label>
            <input
              matInput
              [matDatepicker]="picker1"
              formControlName="Value"
              readonly
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>

        <div *ngSwitchCase="dataType.BOOLEAN">
          <mat-label class="text-capitalize">{{
            field?.get('Label')?.value
          }}</mat-label>
          &nbsp;&nbsp;
          <mat-slide-toggle formControlName="Value"></mat-slide-toggle>
        </div>
        <div *ngSwitchCase="dataType.CUSTOM">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label class="text-capitalize">{{
              field?.get('Label')?.value
            }}</mat-label>
            <mat-select formControlName="Value">
              <mat-option
                class="form-select-option"
                *ngFor="
                  let childConfiguration of field?.get(
                    'ExternalParentConfiguration'
                  )?.value?.externalChildConfigurations
                "
                [value]="childConfiguration.id"
                >{{ childConfiguration.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </ng-container>
    </div>
  </div>
</div>
