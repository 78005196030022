import { Component, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { DataTypes } from 'src/app/shared/data/constants';

@Component({
  selector: 'app-custom-field-view',
  templateUrl: './custom-field-view.component.html',
  styleUrls: ['./custom-field-view.component.scss']
})
export class CustomFieldViewComponent {

  @Input() formArray!: FormArray;
  @Input() parentForm!: FormGroup;
  @Input() formArrayName!: string

  dataType = DataTypes;

}
