import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { DataTypes } from 'src/app/shared/data/constants';

@Component({
  selector: 'app-dynamic-field-view',
  templateUrl: './dynamic-field-view.component.html',
  styleUrls: ['./dynamic-field-view.component.scss'],
})
export class DynamicFieldViewComponent {
  @Input() parentForm!: FormGroup;
  @Input() formArray!: FormArray;

  dataType = DataTypes;
}
