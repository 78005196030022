import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { DataTypes } from '../../../data/constants';

@Component({
  selector: 'app-dynamic-field-form',
  templateUrl: './dynamic-field-form.component.html',
  styleUrls: ['./dynamic-field-form.component.scss'],
})
export class DynamicFieldFormComponent {
  @Input() formArray!: FormArray;
  @Input() parentForm!: FormGroup;
  @Input() dynamicFields: any = [];
  @Output() addField = new EventEmitter<void>();
  @Output() removeField = new EventEmitter<number>();

  dataType = DataTypes;

  selectedDataType: string = '';

  onAddField(): void {
    this.addField.emit();
  }

  onRemoveField(index: number): void {
    this.removeField.emit(index);
  }

  mapDataType(id: string) {
    let dataType: string;
    if (id) {
      dataType = this.dynamicFields?.find(
        (dynamicField) => dynamicField.id == id
      ).dataType;
    }

    return dataType ? dataType : null;
  }

  onLabelIdChange(index: number): void {
    const fieldGroup = this.formArray.at(index) as FormGroup;
    if (fieldGroup) {
      fieldGroup.get('Value')?.reset();
    }
  }
}
