import { Component, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { DataTypes } from 'src/app/shared/data/constants';

@Component({
  selector: 'app-custom-field-form',
  templateUrl: './custom-field-form.component.html',
  styleUrls: ['./custom-field-form.component.scss']
})
export class CustomFieldFormComponent {

  @Input() formArray!: FormArray;
  @Input() parentForm!: FormGroup;
  @Input() formArrayName!: string

  dataType = DataTypes;

}
